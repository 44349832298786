import { IRegistrationFormFields } from './RegistrationForm.interfaces';

const REGISTRATION_FORM_BLOCK_CSS_IDENTIFIERS = Object.freeze({
  form: 'c-registration-form',
});

const REGISTRATION_FORM_DEFAULTS = Object.freeze({
  heading: 'Register',
});

const REGISTRATION_FORM_FIELDS = Object.freeze({
  hasReadAndAgreedToPrivacyStatementAndTermsAndConditions: Object.freeze({
    id: 'has-read-and-agreed-to-privacy-statement-and-terms-and-conditions',
    label: 'I have read and agreed to the Privacy Statement and Terms and Conditions',
  }),
  isABrainCancerSupporter: Object.freeze({
    id: 'is-a-brain-cancer-supporter',
    label: 'I am a brain cancer supporter',
  }),
  isOver18YearsOfAge: Object.freeze({
    id: 'is-over-18-years-of-age',
    label: 'I am at least 18 years of age',
  }),
});

const REGISTRATION_FORM_INITIAL_VALUES: IRegistrationFormFields = {
  confirmPassword: '',
  email: '',
  firstName: '',
  hasReadAndAgreedToPrivacyStatementAndTermsAndConditions: false,
  isABrainCancerSupporter: false,
  isOver18YearsOfAge: false,
  lastName: '',
  password: '',
};

export { REGISTRATION_FORM_BLOCK_CSS_IDENTIFIERS, REGISTRATION_FORM_DEFAULTS, REGISTRATION_FORM_FIELDS, REGISTRATION_FORM_INITIAL_VALUES };
