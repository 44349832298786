import React from 'react';

import { getBEMClassName } from '@netfront/common-library';
import {
  FormField,
  FormFieldContainer,
  FORM_ELEMENT_CSS_IDENTIFIERS,
  FORM_FIELDS,
  IPasswordValidation,
} from '@netfront/gelada-identity-library';
import { Label } from '@netfront/ui-library';
import cx from 'classnames';
import { Form, Formik } from 'formik';
import { useRouter } from 'next/router';
import * as yup from 'yup';

import { Button } from '../Button';
import { Link } from '../Link';

import {
  REGISTRATION_FORM_BLOCK_CSS_IDENTIFIERS,
  REGISTRATION_FORM_FIELDS,
  REGISTRATION_FORM_INITIAL_VALUES,
} from './RegistrationForm.constants';
import { IRegistrationForm } from './RegistrationForm.interfaces';

import { useToast } from '../../hooks/useToast';

const RegistrationForm = ({
  buttonClassName,
  buttonText = 'Register',
  forgotPasswordUrl,
  isSubmitting,
  loginUrl,
  onRegister,
  passwordValidation,
}: IRegistrationForm) => {
  const {
    button: buttonElementCssId,
    buttonsLinks: buttonsLinksElementCssId,
    checkbox: checkboxElementCssId,
    container: containerElementCssId,
    forgotPassword: forgotPasswordElementCssId,
    form: formElementCssId,
    label: labelElementCssId,
    leftContainer: leftContainerElementCssId,
    link: linkCssId,
    login: loginElementCssId,
    name: nameElementCssId,
    register: registerElementCssId,
    rightContainer: rightContainerElementCssId,
  } = FORM_ELEMENT_CSS_IDENTIFIERS;

  const {
    confirmPassword: confirmPasswordField,
    email: emailField,
    firstName: firstNameField,
    lastName: lastNameField,
    password: passwordField,
  } = FORM_FIELDS;

  const { id: confirmPasswordId, label: confirmPasswordLabel } = confirmPasswordField;
  const { id: emailId, label: emailLabel } = emailField;
  const { id: firstNameId, label: firstNameLabel } = firstNameField;
  const { id: lastNameId, label: lastNameLabel } = lastNameField;
  const { id: passwordId, label: passwordLabel } = passwordField;

  const {
    hasReadAndAgreedToPrivacyStatementAndTermsAndConditions: hasReadAndAgreedToPrivacyStatementAndTermsAndConditionsField,
    isABrainCancerSupporter: isABrainCancerSupporterField,
    isOver18YearsOfAge: isOver18YearsOfAgeField,
  } = REGISTRATION_FORM_FIELDS;

  const {
    id: hasReadAndAgreedToPrivacyStatementAndTermsAndConditionsId,
    label: hasReadAndAgreedToPrivacyStatementAndTermsAndConditionsLabel,
  } = hasReadAndAgreedToPrivacyStatementAndTermsAndConditionsField;
  const { id: isABrainCancerSupporterId, label: isABrainCancerSupporterLabel } = isABrainCancerSupporterField;
  const { id: isOver18YearsOfAgeId, label: isOver18YearsOfAgeLabel } = isOver18YearsOfAgeField;

  const { form: formBlockCssId } = REGISTRATION_FORM_BLOCK_CSS_IDENTIFIERS;

  const { maxLength: passwordMaxLength = passwordField.maxLength, minLength: passwordMinLength = passwordField.minLength } =
    passwordValidation ?? ({} as IPasswordValidation);

  const { push } = useRouter();
  const { handleToastError } = useToast();

  const handleLogin = () => {
    if (!loginUrl) {
      return;
    }

    push(loginUrl).catch((error) =>
      handleToastError({
        shouldUseFriendlyErrorMessage: true,
        error,
      }),
    );
  };

  return (
    <Formik
      initialValues={REGISTRATION_FORM_INITIAL_VALUES}
      validationSchema={yup.object().shape({
        confirmPassword: yup
          .string()
          .label(confirmPasswordLabel)
          .max(passwordMaxLength)
          .min(passwordMinLength)
          .oneOf([yup.ref('password')], confirmPasswordField.validationErrorMessage)
          .required(),
        email: yup.string().label(emailLabel).email(emailField.validationErrorMessage).required(),
        firstName: yup.string().label(firstNameLabel).required(),
        hasReadAndAgreedToPrivacyStatementAndTermsAndConditions: yup
          .bool()
          .oneOf([true], `${hasReadAndAgreedToPrivacyStatementAndTermsAndConditionsLabel} is required`),
        isABrainCancerSupporter: yup.bool().oneOf([true], `${isABrainCancerSupporterLabel} is required`),
        isOver18YearsOfAge: yup.bool().oneOf([true], `${isOver18YearsOfAgeLabel} is required`),
        lastName: yup.string().label(lastNameLabel).required(),
        password: yup.string().label(passwordLabel).max(passwordMaxLength).min(passwordMinLength).required(),
      })}
      onSubmit={({ email, firstName, lastName, password }) => onRegister(email, firstName, lastName, password)}
    >
      {({ errors, touched, values }) => {
        const {
          confirmPassword: confirmPasswordError,
          email: emailError,
          firstName: firstNameError,
          hasReadAndAgreedToPrivacyStatementAndTermsAndConditions: hasReadAndAgreedToPrivacyStatementAndTermsAndConditionsError,
          isABrainCancerSupporter: isABrainCancerSupporterError,
          isOver18YearsOfAge: isOver18YearsOfAgeError,
          lastName: lastNameError,
          password: passwordError,
        } = errors;

        const {
          confirmPassword: isConfirmPasswordTouched,
          email: isEmailTouched,
          firstName: isFirstNameTouched,
          hasReadAndAgreedToPrivacyStatementAndTermsAndConditions: hasReadAndAgreedToPrivacyStatementAndTermsAndConditionsTouched,
          // eslint-disable-next-line @typescript-eslint/naming-convention
          isABrainCancerSupporter: isABrainCancerSupporterTouched,
          isOver18YearsOfAge: isOver18YearsOfAgeTouched,
          lastName: isLastNameTouched,
          password: isPasswordTouched,
        } = touched;

        const {
          confirmPassword: confirmPasswordValue,
          email: emailValue,
          firstName: firstNameValue,
          hasReadAndAgreedToPrivacyStatementAndTermsAndConditions: hasReadAndAgreedToPrivacyStatementAndTermsAndConditionsValue,
          // eslint-disable-next-line @typescript-eslint/naming-convention
          isABrainCancerSupporter: isABrainCancerSupporterValue,
          isOver18YearsOfAge: isOver18YearsOfAgeValue,
          lastName: lastNameValue,
          password: passwordValue,
        } = values;

        return (
          <Form className={getBEMClassName(formBlockCssId, formElementCssId)}>
            <FormFieldContainer
              css={{
                blockId: formBlockCssId,
                elementId: `${nameElementCssId}-${containerElementCssId}`,
              }}
            >
              <FormField
                css={{
                  blockId: formBlockCssId,
                  elementId: firstNameId,
                }}
                field={{
                  id: firstNameId,
                  name: 'firstName',
                  labelComponent: (
                    <Label
                      additionalClassNames={getBEMClassName(formBlockCssId, `${firstNameId}-${labelElementCssId}`)}
                      forId={firstNameId}
                      labelText={firstNameLabel}
                    />
                  ),
                  value: firstNameValue,
                }}
                formik={{
                  errors: firstNameError,
                  touched: isFirstNameTouched,
                }}
              />
              <FormField
                css={{
                  blockId: formBlockCssId,
                  elementId: lastNameId,
                }}
                field={{
                  id: lastNameId,
                  name: 'lastName',
                  labelComponent: (
                    <Label
                      additionalClassNames={getBEMClassName(formBlockCssId, `${lastNameId}-${labelElementCssId}`)}
                      forId={lastNameId}
                      labelText={lastNameLabel}
                    />
                  ),
                  value: lastNameValue,
                }}
                formik={{
                  errors: lastNameError,
                  touched: isLastNameTouched,
                }}
              />
            </FormFieldContainer>
            <FormField
              css={{
                blockId: formBlockCssId,
                elementId: emailId,
              }}
              field={{
                id: emailId,
                name: 'email',
                labelComponent: (
                  <Label
                    additionalClassNames={getBEMClassName(formBlockCssId, `${emailId}-${labelElementCssId}`)}
                    forId={emailId}
                    labelText={emailLabel}
                  />
                ),
                type: 'email',
                value: emailValue,
              }}
              formik={{
                errors: emailError,
                touched: isEmailTouched,
              }}
            />
            <FormField
              css={{
                blockId: formBlockCssId,
                elementId: passwordId,
              }}
              field={{
                id: passwordId,
                name: 'password',
                labelComponent: (
                  <Label
                    additionalClassNames={getBEMClassName(formBlockCssId, `${passwordId}-${labelElementCssId}`)}
                    forId={passwordId}
                    labelText={passwordLabel}
                  />
                ),
                type: 'password',
                value: passwordValue,
              }}
              formik={{
                errors: passwordError,
                touched: isPasswordTouched,
              }}
            />
            <FormField
              css={{
                blockId: formBlockCssId,
                elementId: confirmPasswordId,
              }}
              field={{
                id: confirmPasswordId,
                name: 'confirmPassword',
                labelComponent: (
                  <Label
                    additionalClassNames={getBEMClassName(formBlockCssId, `${confirmPasswordId}-${labelElementCssId}`)}
                    forId={confirmPasswordId}
                    labelText={confirmPasswordLabel}
                  />
                ),
                type: 'password',
                value: confirmPasswordValue,
              }}
              formik={{
                errors: confirmPasswordError,
                touched: isConfirmPasswordTouched,
              }}
            />
            <FormField
              css={{
                blockId: formBlockCssId,
                elementId: `${isOver18YearsOfAgeId}-${checkboxElementCssId}`,
              }}
              field={{
                checked: isOver18YearsOfAgeValue,
                id: isOver18YearsOfAgeId,
                name: 'isOver18YearsOfAge',
                labelComponent: (
                  <Label
                    additionalClassNames={getBEMClassName(formBlockCssId, `${isOver18YearsOfAgeId}-${labelElementCssId}`)}
                    forId={isOver18YearsOfAgeId}
                    labelText={isOver18YearsOfAgeLabel}
                  />
                ),
                type: 'checkbox',
              }}
              formik={{
                errors: isOver18YearsOfAgeError,
                touched: isOver18YearsOfAgeTouched,
              }}
            />
            <FormField
              css={{
                blockId: formBlockCssId,
                elementId: `${isABrainCancerSupporterId}-${checkboxElementCssId}`,
              }}
              field={{
                checked: isABrainCancerSupporterValue,
                id: isABrainCancerSupporterId,
                name: 'isABrainCancerSupporter',
                labelComponent: (
                  <Label
                    additionalClassNames={getBEMClassName(formBlockCssId, `${isABrainCancerSupporterId}-${labelElementCssId}`)}
                    forId={isABrainCancerSupporterId}
                    labelText={isABrainCancerSupporterLabel}
                  />
                ),
                type: 'checkbox',
              }}
              formik={{
                errors: isABrainCancerSupporterError,
                touched: isABrainCancerSupporterTouched,
              }}
            />
            <FormField
              css={{
                blockId: formBlockCssId,
                elementId: `${hasReadAndAgreedToPrivacyStatementAndTermsAndConditionsId}-${checkboxElementCssId}`,
              }}
              field={{
                checked: hasReadAndAgreedToPrivacyStatementAndTermsAndConditionsValue,
                id: hasReadAndAgreedToPrivacyStatementAndTermsAndConditionsId,
                name: 'hasReadAndAgreedToPrivacyStatementAndTermsAndConditions',
                labelComponent: (
                  <div className='flex align-center gap-2 nowrap'>
                      I have read and agreed to the{' '}
                    <Link className={cx('color-accent', 'text-underline')} href="/privacy-statement" innerClassName='nowrap' target="_blank">
                        Privacy Statement
                    </Link>{' '}
                      and{' '}
                    <Link className={cx('color-accent', 'text-underline')} href="/terms-and-conditions" innerClassName='nowrap' target="_blank">
                        Terms and Conditions
                    </Link>
                  </div>
                ),
                type: 'checkbox',
              }}
              formik={{
                errors: hasReadAndAgreedToPrivacyStatementAndTermsAndConditionsError,
                touched: hasReadAndAgreedToPrivacyStatementAndTermsAndConditionsTouched,
              }}
            />
            <FormFieldContainer
              css={{
                blockId: formBlockCssId,
                elementId: `${buttonsLinksElementCssId}-${containerElementCssId}`,
              }}
            >
              <FormFieldContainer
                css={{
                  blockId: formBlockCssId,
                  elementId: `${leftContainerElementCssId}-${buttonsLinksElementCssId}`,
                }}
              >
                {loginUrl ? (
                  <>
                    <FormFieldContainer
                      css={{
                        blockId: formBlockCssId,
                        elementId: `already-have-an-account-text-${containerElementCssId}`,
                      }}
                    >
                        Already have an account?
                    </FormFieldContainer>
                    <FormFieldContainer
                      css={{
                        blockId: formBlockCssId,
                        elementId: `${loginElementCssId}-${buttonElementCssId}-${containerElementCssId}`,
                      }}
                    >
                      <Button
                        aria-label="Login"
                        className={cx(getBEMClassName(formBlockCssId, `${loginElementCssId}-${buttonElementCssId}`), buttonClassName)}
                        onPress={handleLogin}
                      >
                          Login
                      </Button>
                    </FormFieldContainer>
                  </>
                ) : null}
              </FormFieldContainer>
              <FormFieldContainer
                css={{
                  blockId: formBlockCssId,
                  elementId: `${rightContainerElementCssId}-${buttonsLinksElementCssId}`,
                }}
              >
                {forgotPasswordUrl ? (
                  <FormFieldContainer
                    css={{
                      blockId: formBlockCssId,
                      elementId: `${forgotPasswordElementCssId}-${linkCssId}-${containerElementCssId}`,
                    }}
                  >
                    <Link
                      className={getBEMClassName(formBlockCssId, `${forgotPasswordElementCssId}-${linkCssId}`)}
                      href={forgotPasswordUrl}
                    >
                        Forgot password
                    </Link>
                  </FormFieldContainer>
                ) : null}
                <FormFieldContainer
                  css={{
                    blockId: formBlockCssId,
                    elementId: `${registerElementCssId}-${buttonElementCssId}-${containerElementCssId}`,
                  }}
                >
                  <button
                    className={cx(getBEMClassName(formBlockCssId, `${registerElementCssId}-${buttonElementCssId}`), buttonClassName)}
                    disabled={isSubmitting}
                    type="submit"
                  >
                    {buttonText}
                  </button>
                </FormFieldContainer>
              </FormFieldContainer>
            </FormFieldContainer>
          </Form>
        );
      }}
    </Formik>

  );
};

export { RegistrationForm };
